//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

//ADO460105 11/05/2022 [DWT]
$primary: #7EB843;
$primary-light: #d1eac0;
$secondary: #0D78AA;

$body-bg: #F5F5F6;
$body-color: #292E31;

// $font-family-sans-serif: 'Source Sans Pro';
// $font-family-monospace: 'Source Sans Pro';
$navbar-height: 6rem;



